import Swiper from "swiper/bundle";
import isMobile from "./helpers/mobile";
const build = () => {
  let swiperCampaign = new Swiper(".campaign .swiper", {
    freeMode: {
      enabled: true,
      sticky: false,
    },
    slidesPerView: "auto",
    spaceBetween: 0,
    loop: false,
    speed: 400,
    freemode: false,
    pagination: {
      el: ".campaign .swiper-nav-wrapper .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".campaign .swiper-nav-wrapper .swiper-button-next",
      prevEl: ".campaign .swiper-nav-wrapper .swiper-button-prev",
    },
    breakpoints: {
      640: {
        freeMode: {
          enabled: false,
          sticky: false,
        },
      },
    },
  });
};

export default () => {
  build();
};
