import Swiper from 'swiper/bundle';

let swiper;
const nations = ['afghanistan', 'bosnia', 'drc', 'iraq', 'kosovo', 'nigeria', 'rwanda', 'south-sudan', 'syria', 'ethiopia', 'myanmar', 'poland', 'ukraine', 'mali', 'burkina-faso', 'palestine', 'sudan']
let innerSwiper = null;

import isMobile from './helpers/mobile'

const build = () => {
    const pagination = document.querySelector('.swiper-pagination')

    if (isMobile()) {
        buildSlider();
    }



}

const buildSlider = e => {
    // var svg = $('.slider-map .swiper-wrapper .swiper-slide').html();
    // var div = '<div class="swiper-slide first" data-nation="first"><div class="section-title fs-56">' + $('.interactive-map .section-title').html() + '</div>' + svg + '</div>';
    // $('.slider-map .swiper-wrapper').html(div);
    // $(nations).each(function() {
    //     var div = '<div class="swiper-slide ' + this + '" data-nation="' + this + '">' + $('.each-content.content-' + this).html() + '</div>';
    //     $('.slider-map .swiper-wrapper').append(div);
    //     $('.slider-map .swiper-slide button.close').remove();

    // });
    setSwiper();
}

const setSwiper = e => {
    swiper = new Swiper('.slider-map .horizontal-swiper', {
        loop: false,
        speed: 600,
        navigation: {
            nextEl: ".slider-map > .horizontal-swiper > .swiper-button-next",
            prevEl: ".slider-map > .horizontal-swiper > .swiper-button-prev",
        },
        scrollbar: {
            el: '.slider-map > .horizontal-swiper > .swiper-scrollbar',
            dragSize: 30,

        },
    })

    $('.slider-map .arrows .arrow-prev').on('click', function() {
        $('.slider-map > .horizontal-swiper > .swiper-button-prev').trigger('click');
    })
    $('.slider-map .arrows .arrow-next').on('click', function() {
        $('.slider-map > .horizontal-swiper > .swiper-button-next').trigger('click');
    })

    swiper.on('slideChange', function() {

        var index = swiper.activeIndex - 1;
        var active;
        if (nations[index]) {
            active = nations[index];

            let currentClass = '.slider-map .each-content.content-' + active + ' .swiper';
            let assetsClass = '.slider-map .each-content.content-' + active;
            let currentSlides = document.querySelectorAll('.slider-map .each-content.content-' + active + ' .swiper .swiper-slide');
            let myElement = document.querySelector(currentClass);
            let exists = document.body.contains(myElement);


            if (innerSwiper) {
                innerSwiper.destroy(true, true);
            }

            if (exists) {
                if (currentSlides.length > 1) {

                    innerSwiper = new Swiper(currentClass, {
                        slidesPerView: 1,
                        loop: true,
                        speed: 400,
                        pagination: {
                            el: assetsClass + ' .swiper-nav-wrapper .swiper-pagination',
                            clickable: true,

                        },

                        navigation: {
                            nextEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-next',
                            prevEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-prev',
                        },

                    });
                } else {
                    $(assetsClass + ' .swiper-nav-wrapper').hide();
                }
            }








        } else {
            active = 'first';
        }

        $('.slider-map .inner').removeClass($('.slider-map').attr('data-nation-active'));
        $('.slider-map').attr('data-nation-active', active);
        $('.slider-map .inner').addClass($('.slider-map').attr('data-nation-active'));






        adjustScroll();


    });
    swiper.on('slideChangeTransitionEnd', function() {
        adjustScroll();
    })
    swiper.on('sliderMove', function(event) {
        adjustScroll();
    });

    swiper.on('transitionEnd', function(event) {
        adjustScroll();
    });
    swiper.on('transitionStart', function(event) {
        adjustScroll();
    });


}


// document.querySelectorAll('.slider-map .each-content').forEach(function(el) {

//     el.classList.forEach(function(cl) {
//         console.log(cl);
//         if (cl != 'each-content') {
//             let currentClass = '.slider-map .each-content.' + cl + ' .swiper';
//             let assetsClass = '.slider-map .each-content.' + cl;
//             let swiperArray = new Swiper(currentClass, {
//                 slidesPerView: 1,
//                 loop: true,
//                 speed: 400,
//                 pagination: {
//                     el: assetsClass + ' .swiper-nav-wrapper .swiper-pagination',
//                     clickable: true,

//                 },

//                 navigation: {
//                     nextEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-next',
//                     prevEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-prev',
//                 },

//             });
//         }

//     })



// });






const adjustScroll = e => {
    var x = $('.slider-map .swiper-scrollbar-drag').css('transform');
    x = x.replace('matrix(1, 0, 0, 1,', '');
    x = x.replace(', 0)', '');
    x = x + 'px';

    $('.slider-map .swiper-scrollbar-progress').css('left', 'calc(' + x + ' - 100vw)');

    if ($('.slider-map > .horizontal-swiper > .swiper-button-next').hasClass('swiper-button-disabled')) {
        $('.slider-map .arrows .arrow-next').css('opacity', 0);
    } else {
        $('.slider-map .arrows .arrow-next').css('opacity', 1);

    }

    if ($('.slider-map > .horizontal-swiper > .swiper-button-prev').hasClass('swiper-button-disabled')) {
        $('.slider-map .arrows .arrow-prev').css('opacity', 0);
    } else {
        $('.slider-map .arrows .arrow-prev').css('opacity', 1);

    }
}


export default () => {
    build()
}
