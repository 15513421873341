let $ = jQuery;
let a = 0;
var gifArray = new Array();
let i = 0;
import SuperGif from "./helpers/libgif.js";
let gifstatus = false;

const build = () => {
  $("img.gif").each(function (i) {
    // gifArray[i] = new SuperGif({ gif: this, loop_mode: false });
    // console.log(gifArray);
    // gifArray[i].load(function() {
    //     console.log('oh hey, now the gif is loaded');
    // });
    // i++;
  });

  $("section.icons .each-col").on("mouseenter", function () {
    $(this)
      .children(".right")
      .addClass("animate__animated animate__pulse animate__infinite");
    $(this)
      .find("img.gif")
      .attr("data-png", $(this).find("img.gif").attr("src"));
    $(this)
      .find("img.gif")
      .attr("src", $(this).find("img.gif").attr("data-gif"));
  });
  $("section.icons .each-col").on("mouseleave", function () {
    $(this)
      .children(".right")
      .removeClass("animate__animated animate__pulse animate__infinite");
  });

  $(window).scroll(function () {
    var oTop = $("section.icons").offset().top - window.innerHeight;
    if (a == 0 && $(window).scrollTop() > oTop) {
      $("section.icons .each-col").trigger("mouseenter");
      $("section.icons .each-col").trigger("mouseleave");
      if (!gifstatus) {
        // $(gifArray).each(function(i) {
        //     console.log('each');
        //     gifArray[i].play();
        //     i++;
        // });
        // $('img.gif').each(function(i) {
        //     $(this).attr('src', $(this).attr('data-gif'));
        //     $(this).css('opacity', '1');
        // });
        // gifstatus = true;
      }
      // i = 0;

      $(".counter-value").each(function () {
        var $this = $(this),
          countTo = $this.attr("data-count");
        $({
          countNum: $this.text(),
        }).animate(
          {
            countNum: countTo,
          },

          {
            duration: 2000,
            easing: "swing",
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
              //alert('finished');
            },
          }
        );
      });
      a = 1;
    }
  });
};

export default () => {
  build();
};
