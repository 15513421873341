import isMobile from './helpers/mobile'
import Swiper from 'swiper/bundle';
let swiper;
let timeout;
let $ = jQuery;

const build = () => {

    if (isMobile()) {

        let swiperStories = new Swiper('.stories .swiper', {
            slidesPerView: 1,
            spaceBetween: 15,
            loop: false,
            speed: 400,
            pagination: {
                el: '.stories .swiper-nav-wrapper .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.stories .swiper-nav-wrapper .swiper-button-next',
                prevEl: '.stories .swiper-nav-wrapper .swiper-button-prev',
            },

        })


    }

}


export default () => {
    build()
}
